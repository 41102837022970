import { useColorModeValue } from '@chakra-ui/react';

export default function useColors() {
  return {
    text: {
      'default': useColorModeValue('white', 'gray.400'),
      active: useColorModeValue('white', 'gray.50'),
      hover: 'gray.200',
    },
    bg: {
      'default': 'transparent',
      active: useColorModeValue('#1f5873', '#1f5873'),
    },
    border: {
      'default': 'divider',
      active: useColorModeValue('blue.50', 'gray.800'),
    },
  };
}
