import {
  Text,
  HStack,
  Box,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import type { NavGroupItem } from 'types/client/navigation-items';

import IconSvg from 'ui/shared/IconSvg';

import NavLinkIcon from './NavLinkIcon';
import useNavLinkStyleProps from './useNavLinkStyleProps';
import NavLinkItem from './NavLinkItem';

type Props = {
  item: NavGroupItem;
  isCollapsed?: boolean;
}

const NavLinkGroupDesktop = ({ item, isCollapsed }: Props) => {
  const isExpanded = isCollapsed === false;

  const styleProps = useNavLinkStyleProps({ isCollapsed, isExpanded, isActive: item.isActive });
  const isActive = item.isActive;

  return (
    <Box as="li" listStyleType="none" w="100%">
      <Popover
        trigger="hover"
        placement="bottom-end"
        isLazy
      >
        <PopoverTrigger>
          <Link
            { ...styleProps.itemProps }
            pl={{ lg: 4, xl: 6}}
            pr={{ lg: 4, xl: 8}}
            aria-label={ `${ item.text } link group` }
            position="relative"
            borderBottom={ isActive ? "3px solid #fff" : "3px solid transparent"}
            borderRadius={0}
            _hover={{
              '&': {
                color: isActive ? "" : "white",
                borderColor: "#fff",
              },
            }}
          >
            <HStack spacing={ 3 } overflow="hidden" display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Text
                { ...styleProps.textProps }
              >
                { item.text }
              </Text>
              <IconSvg
                name="arrows/east-mini"
                position="absolute"
                display={{lg: "none", xl: "block"}}
                right="6px"
                transform="rotate(-90deg)"
                boxSize={ 5 }
                transitionProperty="opacity"
                transitionDuration="normal"
                transitionTimingFunction="ease"
              />
            </HStack>
          </Link>
        </PopoverTrigger>
        <PopoverContent width="252px" top={{ lg: '10px' }} 
          bgColor='#fff'
          borderTopRadius={0}
          borderBottomRadius={ 5 }
          borderTop={"5px solid #0784c3e6"}
        >
          <PopoverBody p={ 2 }>
            <VStack spacing={ 'px' } alignItems="start">
              { item.subItems.map((subItem, index) => Array.isArray(subItem) ? (
                <Box
                  key={ index }
                  w="100%"
                  as="ul"
                  _notLast={{
                    mb: 2,
                    pb: 2,
                    borderBottomWidth: '1px',
                    borderColor: 'divider',
                  }}
                >
                  { subItem.map(subSubItem => <NavLinkItem key={ subSubItem.text } item={ subSubItem } isCollapsed={ false }/>) }
                </Box>
              ) :
                <NavLinkItem key={ subItem.text } item={ subItem } isCollapsed={ false }/>,
              ) }
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default NavLinkGroupDesktop;
