import { useColorMode } from '@chakra-ui/react';
import React from 'react';

import * as cookies from 'lib/cookies';

import { COLOR_THEMES } from './utils';

const SettingsColorTheme = () => {
  const { setColorMode } = useColorMode();

  const setTheme = React.useCallback((hex: string) => {
    const nextTheme = COLOR_THEMES.find((theme) => theme.hex === hex);

    if (!nextTheme) {
      return;
    }

    setColorMode('light');

    const varName = nextTheme.colorMode === 'light' ? '--chakra-colors-white' : '--chakra-colors-black';
    window.document.documentElement.style.setProperty(varName, hex);

    cookies.set(cookies.NAMES.COLOR_MODE_HEX, hex);
    window.localStorage.setItem(cookies.NAMES.COLOR_MODE, nextTheme.colorMode);
  }, [setColorMode]);

  React.useEffect(() => {
    const cookieColorMode = cookies.get(cookies.NAMES.COLOR_MODE);

      if (cookieColorMode === 'dark') {
        setTimeout(() => {
          cookies.set(cookies.NAMES.COLOR_MODE, 'light')
          setTheme("#FFFFFF");
        }, 300)
        return
      }
  
    setTheme("#FFFFFF");
    // should run only on mount
  }, []);

  return ( null );

};

export default React.memo(SettingsColorTheme);
 