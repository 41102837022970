import { Flex, Box, VStack, useColorModeValue, HStack } from '@chakra-ui/react';
import React from 'react';

import { useAppContext } from 'lib/contexts/app';
import * as cookies from 'lib/cookies';
import useHasAccount from 'lib/hooks/useHasAccount';
import useNavItems, { isGroupItem } from 'lib/hooks/useNavItems';
import getDefaultTransitionProps from 'theme/utils/getDefaultTransitionProps';
import NetworkLogo from 'ui/snippets/networkMenu/NetworkLogo';
import NavLinkGroupDesktop from './NavLinkGroupDesktop';
import NavLinkItem from './NavLinkItem';
import NavLink from './NavLink';

type Props = {
  isHomePage?: boolean;
}
const NavigationDesktop = ({ isHomePage }:Props) => {
  const appProps = useAppContext();
  const cookiesString = appProps.cookies;
  const [sticked, setSticked] = React.useState<boolean>(false)

  React.useEffect(() => {
    const handleScroll = () => {
      const offsetY = window.scrollY;
      if(offsetY > 38) {
        setSticked(true)
      } else {
        setSticked(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []); 

  const isNavBarCollapsedCookie = cookies.get(cookies.NAMES.NAV_BAR_COLLAPSED, cookiesString);
  let isNavBarCollapsed;
  if (isNavBarCollapsedCookie === 'true') {
    isNavBarCollapsed = true;
  }
  if (isNavBarCollapsedCookie === 'false') {
    isNavBarCollapsed = false;
  }

  const { mainNavItems, accountNavItems } = useNavItems();

  const hasAccount = useHasAccount();

  const [ isCollapsed, setCollapsedState ] = React.useState<boolean | undefined>(isNavBarCollapsed);

  const isExpanded = isCollapsed === false;

  return (
    
    <Flex
      display={{ base: 'none', lg: 'flex' }}
      position="sticky"
      top={ 0 }
      flexDirection="row"
      alignItems="stretch"
      bgColor={ isHomePage ? ( sticked ? 'rgb(5, 119, 136)' : 'transparent') : 'rgb(5, 119, 136)' }
      px={{ lg: isExpanded ? 6 : 4, xl: isCollapsed ? 4 : 6 }}
      py={{ lg: 5 }}
      mt={'-1px'}
      zIndex={ 9 }
      width={"100%"}
      { ...getDefaultTransitionProps({ transitionProperty: 'width, padding' }) }
      sx={{
        '&:hover #expand-icon': {
          display: 'block',
        },
      }}
    >
      <Box
        as="header"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        w="100%"
        pl={{ lg: 3, xl: '15px' }}
        pr={{ lg: 3, xl: '15px' }}
        h={ 10 }
        transitionProperty="padding"
        transitionDuration="normal"
        transitionTimingFunction="ease"
      >
        <NetworkLogo/>
        <Box as="nav">
          <HStack
            spacing={3}
            display={{ base: 'none', lg: 'flex' }}
            position="relative"
            alignItems={"center"}
            flexDirection="row"
            px={{ lg: 4, xl: 4 }}
          >
          { mainNavItems.map((item) => {
              if (isGroupItem(item)) {
                return <NavLinkGroupDesktop key={ item.text } item={ item }/>;
              } else {
                return <NavLink key={ item.text } item={ item }/>;
              }
            }) }
          </HStack>
        </Box>
      </Box>
      { hasAccount && (
        <Box as="nav" >
          <Flex as="ul"
          display={{ base: 'none', lg: 'flex' }}
          position="relative"
          flexDirection="row"
          px={{ lg: 4, xl: 4 }}
        >
            { accountNavItems.map((item) => <NavLinkItem key={ item.text } item={ item }/>) }
          </Flex>
        </Box>
      ) }
    </Flex>
  );
};

export default NavigationDesktop;
