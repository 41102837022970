import React from 'react';

import { Box, Flex, Heading } from '@chakra-ui/react';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import ProfileMenuDesktop from 'ui/snippets/profileMenu/ProfileMenuDesktop';
import WalletMenuDesktop from 'ui/snippets/walletMenu/WalletMenuDesktop';
import config from 'configs/app';

const HomeSearchExplorerSection = () => {

    return (
        <Box
            w="100%"
            marginTop={{ base: '-57px', lg: '-80px' }}
            backgroundImage={'url(/static/bannertobescan.png)'}
            bgPosition={{ base: 'left', lg:'right' }}
            bgSize={'cover'}
            pt={{ base: '96px', lg: '196px' }}
            px={{ base: '24px', lg: '48px' }}
            pb={{ base: '24px', lg: '96px' }}
            minW={{ base: 'unset', lg: '900px' }}
            data-label="hero plate"
        >
            <Flex mb={{ base: 3, lg: 5 }} justifyContent="space-between" alignItems="center">
                <Heading
                    as="h1"
                    size={{ base: 'lg', lg: "2xl" ,xl: '3xl' }}
                    lineHeight={{ base: '32px', lg: '50px' }}
                    pl={{base: 0, lg: '145px'}}
                    flexGrow={1}
                    fontWeight={{base: 600, lg: 700}}
                    textAlign={'center'}
                    color={config.UI.homepage.plate.textColor}
                >
                    {config.chain.name}
                </Heading>
                <Box display={{ base: 'none', lg: 'flex' }}>
                    {config.features.account.isEnabled && <ProfileMenuDesktop isHomePage />}
                    {config.features.blockchainInteraction.isEnabled && <WalletMenuDesktop isHomePage />}
                </Box>
            </Flex>
            <SearchBar isHomepage />
        </Box>

    )
}

export default HomeSearchExplorerSection;
