import { route } from 'nextjs-routes';
import { Box, Center, Container, Flex, Grid, GridItem, GridProps, Image, Link, Text, VStack, Icon, HStack } from '@chakra-ui/react';
import IconSvg from 'ui/shared/IconSvg';
import React from 'react';
const linkGroup = [
  {
    title: 'Company',
    link: [
      { content: 'About Us', url: '#' },
      { content: 'Brand Assets', url: '#' },
      { content: 'Contact Us', url: '#' },
      { content: 'Careers', url: '#' },
      { content: 'Terms & Privacy', url: '#' },
      { content: 'Bug Bounty', url: '#' }
    ]
  },
  {
    title: 'Community',
    link: [
      { content: 'API Documentation', url: '#' },
      { content: 'Knowledge Base', url: '#' },
      { content: 'Network Status', url: '#' },
      { content: 'News letters', url: '#' }
    ]
  },
  {
    title: 'Products & Services',
    link: [
      { content: 'Advertise', url: '#' },
      { content: 'Explorer-as-a-Service(EaaS)', url: '#' },
      { content: 'API Plans', url: '#' },
      { content: 'Priotity Support', url: '#' },
      { content: 'Blockscan', url: '#' },
      { content: 'Blockscan Chat', url: '#' }
    ]
  }
];

const LIST_ICON = [
  {
    name: 'social/twitter_filled' as const,
    size: '36px',
    sizeMobile: '30px',
    url: 'https://twitter.com/tobechain'
  },
  {
    name: 'social/telegram_filled' as const,
    size: '36px',
    sizeMobile: '30px',
    url: 'https://t.me/tobechainglobal'
  },
  {
    name: 'social/facebook_filled' as const,
    size: '36px',
    sizeMobile: '30px', 
    url: 'https://www.facebook.com/tobechain'
  },
  {
    name: 'social/youtube' as const,
    size: '30px',
    sizeMobile: '25px',
    url: '#'
  }
];

function scrollToTop() {
  var section = document.getElementById('top-navbar-01');
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
}

const containerProps: GridProps = {
  h: { base: '100%', lg: '280px' },
  templateColumns: { base: 'repeat(2, 1fr)', lg: 'repeat(5, 1fr)' },
  gap: { base: 2, lg: 6 },
  px: { base: 0, lg: 8 },
  pt: { base: 6, lg: 10 },
  pb: { base: 1, lg: 5 }
};

const Footer = () => {
  return (
    <Box as="footer" pt={{ base: 4, lg: 6 }}>
      {/* Icon */}
      <Box px={{ base: 8, lg: 24 }} borderTop={'1px solid #cfd3d7'} bg="#057788">
        <Container maxW={'container.xl'} px={ 0 }>
          <Grid {...containerProps}>
            <GridItem colSpan={2} h="100%" mb={{ base: 5, lg: 0 }}>
              <Flex
                h={'100%'}
                w={'100%'}
                flexDirection={'column'}
                justify={'center'}
                alignItems={'start'}
                rowGap={{ base: 3, lg: 5 }}
                mb={{base: 5, lg: 0}}
              >
                <Box
                  as="a"
                  href={ route({ pathname: '/' }) }
                  width={{ base: '200px', lg: '275px' }}
                  height={{ base: '45px', lg:  '65px'}}
                  display="inline-flex"
                  overflow="hidden"
                  flexGrow={0}
                  aria-label="Link to main page"
                >
                  <Image src={'/static/network_logo.png'} alt="icon-logo-tobescan" w='auto' h='100%' />
                </Box>

                <Text fontSize="sm" color={'gray.300'}>
                  Tobescan Testnet is a Block Explorer and Analytics Platform for TobeChain, a decentralized smart contracts
                  platform.
                </Text>
                <HStack spacing={{base: 1, lg: 2}}>
                  {LIST_ICON.map((icon, _index) => (
                    <Link key={'footer-item-'+_index} href={ icon.url } target='_blank' display="flex" alignItems="center" h="40px" color={"gray.200"}
                    _hover={{
                      '&': {
                        color: "white"
                      }
                    }}>
                      <Center minW={ 6 }>
                        <IconSvg boxSize={{base: icon.size}} name={ icon.name }/>
                      </Center>
                    </Link>
                  ))}
                </HStack>
              </Flex>
            </GridItem>
            {linkGroup.map((itemlink, index) => (
              <GridItem
                key={"GridItem"+index}
                colSpan={{base: index === 2 ? 2 : 1, lg: 1}}
                h="100%"
                pl={{ base: 0, lg: 3 }}
                mb={{ base: 5, lg: 0 }}
              >
                <VStack spacing={1} alignItems="start">
                  <Text fontWeight="600" mb="1" fontSize="md" color={'gray.50'}>
                    {itemlink.title}
                  </Text>
                  <Box as={'ul'} listStyleType={'none'}>
                    {itemlink.link.map((subLink, _subIndex) => (
                      <Box as="li" key={_subIndex}>
                        <Link
                          color={'gray.300'}
                          key={_subIndex}
                          href={subLink.url}
                          display="flex"
                          alignItems="center"
                          h="26px"
                          variant="secondary"
                          // target="_blank"
                          _hover={{
                            '&': {
                              color: 'white',
                            },
                          }}
                          fontSize="xs"
                          flexWrap={{ base: 'nowrap', lg: 'wrap' }}
                        >
                          {subLink.content}
                        </Link>
                      </Box>
                    ))}
                  </Box>
                </VStack>
              </GridItem>
            ))}
          </Grid>
        </Container>

        <Flex borderTop={'1px solid #d7d7d7'} justifyContent={"space-between"} alignItems={"center"} >
          <Text fontSize={{ base: 'xs', lg: 'sm' }} fontWeight={500} py={{ base: 2, lg: 3 }} color={'gray.300'}>
            TobeScan Testnet © 2024
          </Text>

          
        </Flex>
      </Box>
    </Box>
  );
};

export default Footer;
