import { useRouter } from 'next/router';
import React from 'react';

import type { NavItemInternal, NavItem, NavGroupItem } from 'types/client/navigation-items';

import config from 'configs/app';
import UserAvatar from 'ui/shared/UserAvatar';
// import { url } from 'inspector';

interface ReturnType {
  mainNavItems: Array<NavItem | NavGroupItem>;
  accountNavItems: Array<NavItem>;
  profileItem: NavItem;
}

export function isGroupItem(item: NavItem | NavGroupItem): item is NavGroupItem {
  return 'subItems' in item;
}

export function isInternalItem(item: NavItem): item is NavItemInternal {
  return 'nextRoute' in item;
}

export default function useNavItems(): ReturnType {
  const router = useRouter();
  const pathname = router.pathname;

  return React.useMemo(() => {
    let blockchainNavItems: Array<NavItem> | Array<Array<NavItem>> = [];
    let NFTsNavItems: Array<NavItem> | Array<Array<NavItem>> = [];
    let developersNavItems: Array<NavItem> | Array<Array<NavItem>> = [];

    // Blockchain list
    const txs: NavItem | null = {
      text: 'Transactions',
      nextRoute: { pathname: '/txs' as const },
      icon: 'transactions',
      isActive: pathname === '/txs' || pathname === '/tx/[hash]',
    };
    const blocks: NavItem | null = {
      text: 'View Blocks',
      nextRoute: { pathname: '/blocks' as const },
      icon: 'block',
      isActive: pathname === '/blocks' || pathname === '/block/[height_or_hash]',
    };
    const topAccounts: NavItem | null = !config.UI.views.address.hiddenViews?.top_accounts ? {
      text: 'Top accounts',
      nextRoute: { pathname: '/accounts' as const },
      icon: 'top-accounts',
      isActive: pathname === '/accounts',
    } : null;
    const userOps: NavItem | null = config.features.userOps.isEnabled ? {
      text: 'User operations',
      nextRoute: { pathname: '/ops' as const },
      icon: 'user_op',
      isActive: pathname === '/ops' || pathname === '/op/[hash]',
    } : null;
    const verifiedContracts: NavItem | null = {
      text: 'Verified contracts',
      nextRoute: { pathname: '/verified-contracts' as const },
      icon: 'verified',
      isActive: pathname === '/verified-contracts',
    };
    const ensLookup = config.features.nameService.isEnabled ? {
      text: 'Name services lookup',
      nextRoute: { pathname: '/name-domains' as const },
      icon: 'ENS',
      isActive: pathname === '/name-domains' || pathname === '/name-domains/[name]',
    } : null;
    // const validators = config.features.validators.isEnabled ? {
    //   text: 'Top validators',
    //   nextRoute: { pathname: '/validators' as const },
    //   icon: 'validator',
    //   isActive: pathname === '/validators',
    // } : null;

    blockchainNavItems = [
      txs,
      blocks,
      topAccounts,
      userOps,
      // validators,
      verifiedContracts,
      ensLookup,
      config.features.beaconChain.isEnabled && {
        text: 'Withdrawals',
        nextRoute: { pathname: '/withdrawals' as const },
        icon: 'arrows/north-east',
        isActive: pathname === '/withdrawals',
      },
    ].filter(Boolean);

    // NFTs list
    NFTsNavItems = [
      {
        text: 'Top NFTs',
        url: '#',
      },
      {
        text: 'Top Mints',
        url: '#',
      },
      {
        text: 'Latest Trades',
        url: '#',
      },
      {
        text: 'Latest Transfers',
        url: '#',
      },
      {
        text: 'Latest Mints',
        url: '#',
      },
    ];

    // Developer NavItem list
    developersNavItems = [
      {
        text: 'API Plans',
        nextRoute: { pathname: '/api-docs' as const },
        isActive: pathname === '/api-docs',
      },
      config.features.graphqlApiDocs.isEnabled ? {
        text: 'GraphQL',
        nextRoute: { pathname: '/graphiql' as const },
        isActive: pathname === '/graphiql',
      } : null,
      {
        text: 'Documentation',
        url: 'https://docs.tobescan.com',
      },
      {
        text: 'Verify contract',
        nextRoute: { pathname: '/contract-verification' as const },
        isActive: pathname.startsWith('/contract-verification'),
      },
      {
        text: 'Similar Contract Search',
        url: '#',
      },
      {
        text: 'Contract Diff Checker',
        icon: 'hr',
        url: '#',
      },
      {
        text: 'Vyper Online Compiler',
        url: '#',
      },
      {
        text: 'Bytecode to Opcode',
        url: '#',
      },
      {
        text: 'Broadcast Transaction',
        url: '#',
      },
    ].filter(Boolean);

    // const apiNavItems: Array<NavItem> = [
    //   config.features.graphqlApiDocs.isEnabled ? {
    //     text: 'GraphQL',
    //     nextRoute: { pathname: '/graphiql' as const },
    //     icon: 'graphQL',
    //     isActive: pathname === '/graphiql'
    //   } : null
    // ].filter(Boolean);

    const mainNavItems: ReturnType['mainNavItems'] = [
      {
        text: 'Home',
        nextRoute: { pathname: '/' as const },
        icon: 'home',
        isActive: pathname === '/',
      },
      {
        text: 'Blockchain',
        icon: 'globe-b',
        isActive: blockchainNavItems.flat().some((item) => isInternalItem(item) && item.isActive),
        subItems: blockchainNavItems,
      },
      {
        text: 'Validators',
        icon: 'globe-b',
        isActive: false,
        subItems: [
          {
            text: 'Validators Leaderboard',
            url: '#',
            isActive: false,
          },
          {
            text: 'View Validators Set Info',
            url: '#',
            isActive: false,
          },
        ].filter(Boolean),
      },
      {
        text: 'Tokens',
        icon: 'token',
        isActive: pathname.startsWith('/token'),
        subItems: [
          {
            text: 'Tokens',
            nextRoute: { pathname: '/tokens' as const },
            isActive: pathname === '/tokens',
          },
          {
            text: 'Token Transfer',
            nextRoute: { pathname: '/tokens' as const },
            isActive: pathname.startsWith('/tokens?type=ERC-20'),
          },
        ],
      },
      {
        text: 'NFTs',
        icon: 'token',
        isActive: NFTsNavItems.some((item) => isInternalItem(item) && item.isActive),
        subItems: NFTsNavItems,
      },
      {
        text: 'Resources',
        nextRoute: { pathname: '/tokens' as const },
        icon: 'token',
        isActive: pathname.startsWith('/stats'),
        subItems: [
          config.features.stats.isEnabled ? {
            text: 'Charts & stats',
            nextRoute: { pathname: '/stats' as const },
            icon: 'stats',
            isActive: pathname === '/stats',
          } : null,
          {
            text: 'Top Statistics',
            url: '#',
          },
        ],
      },
      {
        text: 'Developers',
        icon: 'token',
        isActive: developersNavItems.some((item) => isInternalItem(item) && item.isActive),
        subItems: developersNavItems,
      },
      {
        text: 'Other',
        icon: 'gear',
        subItems: [
          config.features.gasTracker.isEnabled && {
            text: 'Gas tracker',
            nextRoute: { pathname: '/gas-tracker' as const },
            isActive: pathname.startsWith('/gas-tracker'),
          },
          {
            text: 'TOBE Faucet',
            url: 'https://faucet.tobescan.com/faucet',
          },
        ].filter(Boolean),
      },
    ].filter(Boolean);

    const accountNavItems: ReturnType['accountNavItems'] = [
      {
        text: 'Watch list',
        nextRoute: { pathname: '/account/watchlist' as const },
        icon: 'watchlist',
        isActive: pathname === '/account/watchlist',
      },
      {
        text: 'Private tags',
        nextRoute: { pathname: '/account/tag-address' as const },
        icon: 'privattags',
        isActive: pathname === '/account/tag-address',
      },
      {
        text: 'Public tags',
        nextRoute: { pathname: '/account/public-tags-request' as const },
        icon: 'publictags',
        isActive: pathname === '/account/public-tags-request',
      },
      {
        text: 'API keys',
        nextRoute: { pathname: '/account/api-key' as const },
        icon: 'API',
        isActive: pathname === '/account/api-key',
      },
      {
        text: 'Custom ABI',
        nextRoute: { pathname: '/account/custom-abi' as const },
        icon: 'ABI',
        isActive: pathname === '/account/custom-abi',
      },
      config.features.addressVerification.isEnabled && {
        text: 'Verified addrs',
        nextRoute: { pathname: '/account/verified-addresses' as const },
        icon: 'verified',
        isActive: pathname === '/account/verified-addresses',
      },
    ].filter(Boolean);

    const profileItem = {
      text: 'My profile',
      nextRoute: { pathname: '/auth/profile' as const },
      iconComponent: UserAvatar,
      isActive: pathname === '/auth/profile',
    };

    return { mainNavItems, accountNavItems, profileItem };
  }, [ pathname ]);
}
