import { Link, Text, HStack, Tooltip, Box, useBreakpointValue, chakra, shouldForwardProp } from '@chakra-ui/react';
import NextLink from 'next/link';
import React from 'react';

import type { NavItem } from 'types/client/navigation-items';

import { route } from 'nextjs-routes';

import useIsMobile from 'lib/hooks/useIsMobile';
import { isInternalItem } from 'lib/hooks/useNavItems';
import IconSvg from 'ui/shared/IconSvg';

import NavLinkIcon from './NavLinkIcon';
import useColors from './useColors';
import useNavLinkStyleProps from './useNavLinkStyleProps';

type Props = {
  item: NavItem;
  isCollapsed?: boolean;
  px?: string | number;
  className?: string;
  onClick?: () => void;
}

const NavLink = ({ item, isCollapsed, px, className, onClick }: Props) => {
  const isMobile = useIsMobile();
  const colors = useColors();

  const isInternalLink = isInternalItem(item);

  // const styleProps = useNavLinkStyleProps({ isActive: isInternalLink && item.isActive });

  const href = isInternalLink && !!item.nextRoute ? route(item.nextRoute) : '#';
  const isActive = isInternalLink && item.isActive

  const itemProps = {
    py: 2.5,
    display: 'flex',
    color: isActive ? "white" : "gray.200",
    borderBottom: isActive && !isMobile ? "3px solid #fff" : "3px solid transparent"
  }

  const textProps = {
    variant: 'inherit',
    fontSize: 'sm',
    lineHeight: '20px',
    transitionProperty: 'opacity',
    transitionDuration: 'normal',
    transitionTimingFunction: 'ease',
  }

  const content = (
    <Link
      href={ href }
      target={ isInternalLink ? '_self' : href === '#' ? '_self' : '_blank' }
      { ...itemProps }
      w={{ base: '100%' }}
      display="block"
      px={{ base: 3, lg: 3 , xl: 5 }}
      aria-label={ `${ item.text } link` }
      whiteSpace="nowrap"
      onClick={ onClick }
      // borderBottom={"3px solid transparent"}
      _hover={{
        '&': {
          borderColor: "#fff",
        },
      }}
    >
      <Tooltip
        label={ item.text }
        hasArrow={ false }
        isDisabled={ true }
        placement="bottom-end"
        variant="nav"
        gutter={ 20 }
        color={ isInternalLink && item.isActive ? colors.text.active : colors.text.hover }
      >
        <HStack spacing={ 3 } overflow="hidden">
          <Text { ...textProps }>
            <span>{ item.text }</span>
            { !isInternalLink && <IconSvg name="arrows/north-east" boxSize={ 4 } color="text_secondary" verticalAlign="middle"/> }
          </Text>
        </HStack>
      </Tooltip>
    </Link>
  );

  return (
    <Box as="li" listStyleType="none" w="100%" className={ className }>
      { isInternalLink && !!item.nextRoute ? (
        <NextLink href={ item.nextRoute } legacyBehavior>
          { content }
        </NextLink>
      ) : content }
    </Box>
  );
};

const NavLinkChakra = chakra(NavLink, {
  shouldForwardProp: (prop) => {
    const isChakraProp = !shouldForwardProp(prop);

    if (isChakraProp && prop !== 'px') {
      return false;
    }

    return true;
  },
});

export default React.memo(NavLinkChakra);
